<script>
import {gerarFormalizacaoLote} from '@/domain/leiloes/services'
import {enviarFormalizacaoLote} from "@/domain/leiloes/services/financeiro"
// import {donwloadFile} from "@/utils/downloadFile"

function initialState () {
  return {
    open: false,
    htmlLoaded: false,
    html: '',
    arrematante: {},
    dados: {},
    anexos: [],
    emails: [],
    lote: null,
    enviando: false,
    enviadoSucesso: null,
    total: null,
    totalArremates: null,
    totalImpostos: null,
    adicionarRemetentes: false,
    remetentesAdicionais: null
  }
}

export default {
  name: 'email-formalizacao-view',
  data() {
    return initialState()
  },
  computed: {},
  mounted() {
  },
  destroyed() {
    this._unmount()
  },
  methods: {
    load(loteId) {
      this.htmlLoaded = false
      this.html = ''
      this.arrematante = {}
      this.dados = {}
      this.anexos = []
      this.emails = []
      this.enviando = false
      this.enviadoSucesso = null
      this.lote = loteId
      let _body = document.querySelector('body')
      _body.classList.add('hide-scroll')
      this.html = '<i class="fa fa-spinner fa-spin"></i>'
      this.open = true
      gerarFormalizacaoLote(loteId)
          .then((response) => {
            this.bindLoad(response)
            console.log(response)
          })
          .catch((error) => {
            this.alertApiError(error)
          })
    },
    close() {
      this.open = false
      this._unmount()
    },
    bindLoad(response) {
      this.html = response.data.template.template
      this.arrematante = response.data.arrematante
      this.dados = response.data
      this.htmlLoaded = true
      this.emails = this.arrematante.pessoa.emails ? this.arrematante.pessoa.emails.map((e) => e.email) : null
      this.telefones = this.arrematante.pessoa.phoneNumbers || null
    },
    _unmount() {
      this.htmlLoaded = false
      let _body = document.querySelector('body')
      _body.classList.remove('hide-scroll')
      Object.assign(this.$data, initialState())
    },
    enviar() {
      let emails = this.emails
      if (this.remetentesAdicionais) {
        emails = emails.concat(this.remetentesAdicionais.split('\n'))
      }
      let telefones = this.telefones
      this.enviando = true
      this.enviadoSucesso = null
      let dados = {
        ...this.dados,
        emails: emails,
        telefones: telefones,
        html: this.$refs.html.innerHTML
      }
      enviarFormalizacaoLote(dados)
          .then((response) => {
            this.enviando = false
            this.enviadoSucesso = true
            this.dados.lote = response.data.lote
            console.log(response)
            this.$emit('update')
            this.$emit('formalizacaoEnviada', this.dados.lote, response.data.formalizadoPor)
          })
          .catch((error) => {
            this.enviando = false
            this.enviadoSucesso = false
            this.alertApiError(error)
          })
    }
  },
  components: {}
}
</script>

<template>
  <div>
    <div class="side-panel-overlay" @click="close" :class="{'open': open}">
    </div>
    <div class="view-container" :class="{'open': open}">
      <span @click="close" class="side-panel-close" title="Close">
        <span class="side-panel-close-inner"></span>
      </span>
      <span class="side-panel-print side-panel-print-visible" title="Print"></span>
      <div class="panel-opt">
        <u-btn @click="enviar" :disabled="enviando" color="green" :class="{'btn-addon': !enviando}">
          <span v-if="!enviando"><i class="fa fa-handshake m-r"></i> Registrar e Enviar Formalização</span>
          <span v-if="enviando"><i class="fa fa-spin fa-spinner m-r"></i> Aguarde</span>
        </u-btn>
        <span v-if="enviadoSucesso === true" class="block m-t sl-alert alert-success bg-success">Enviado com sucesso</span>
        <span v-else-if="enviadoSucesso === false" class="block m-t sl-alert bg-danger">Aconteceu algum problema ao tentar processar o envio da cobrança</span>
        <span v-else></span>
      </div>
      <div class="panel-rest">
        <div ref="html" class="inner-html" :class="{'loaded': htmlLoaded}" v-html="html" contentEditable="true"></div>
        <div class="options" v-if="htmlLoaded">
          <div class="wrapper-sm">
            <div class="tip1">
              <div class="title">Arrematante:</div>
              <div class="value">{{ arrematante.apelido }}</div>
            </div>
            <div class="tip1">
              <div class="value_ font-bold font-12 m-b-xs">{{ arrematante.apelido }}</div>
              <div class="value_">{{ arrematante.pessoa.name }}</div>
              <div class="value_ m-t-xs font-12 text-blue-grey-5">{{ arrematante.pessoa.type === 1 ? 'Pessoa Física' : 'Pessoa Jurídica'  }}</div>
              <div v-if="telefones">
                <div class="tip1" v-for="phone in telefones">
                  <div class="title">Telefone:</div>
                  <div class="value">
                    {{ phone.phoneNumber|formataTelefone }}
                  </div>
                </div>
              </div>
            </div>

            <div class="tip1">
              <div class="title">Valor a Receber:</div>
              <div class="value">R$ {{ (dados.lote.valorArremate + dados.lote.valorImpostos)|moeda }}</div>
            </div>

            <div class="tip2">
              Linha do tempo
            </div>
            <div class="tip1"  v-for="h in dados.lote.historicoFormalizacoes">
              <div class="title">
                {{h.message.id}}
                <div class="font-10">Protocolo ID</div>
              </div>
              <div class="value">
                <div>
                  <div class="m-b-xs">Data Registro</div>
                  <p style="margin: 0; padding: 0" class="font-12">{{ h.message.dateRegistry|formatDate('dd/MM/yyyy HH:mm:ss') }} </p>
                  <div class="m-t-sm m-b-xs" style="padding-top: 6px; border-top: #CCCCCC 1px dashed">Data Envio</div>

                  <p v-if="h.message.dateSend" style="margin: 0; padding: 0" class="font-12">{{ h.message.dateSend|formatDate('dd/MM/yyyy HH:mm:ss') }} </p>
                  <p v-else style="margin: 0; padding: 0" class="font-12 text-negative">Não enviado</p>

                  <div class="m-t-sm m-b-xs" style="padding-top: 6px; border-top: #CCCCCC 1px dashed">Data Leitura</div>
                  <p v-if="h.message.dateReaded" style="margin: 0; padding: 0" class="font-12">{{ h.message.dateReaded|formatDate('dd/MM/yyyy HH:mm:ss') }} </p>
                  <p v-else style="margin: 0; padding: 0" class="font-12 text-negative">Não lido</p>
                </div>
              </div>
            </div>
<!--            <div class="tip1" v-if="!historicoCobranca">
              Nenhuma cobrança enviada até o momento
            </div>-->
            <div class="tip2">
              Destinatários
            </div>
            <div class="tip1" v-for="email in arrematante.pessoa.emails">
              <div class="title">Email:</div>
              <div class="value">
                {{ email.email }}
              </div>
            </div>
            <div class="m-t-xs" v-if="adicionarRemetentes">
              <div class="m-t-xs m-b-xs font-12 text-blue-grey-5">Separe os e-mails por linha</div>
              <textarea autofocus class="sl-textarea" rows="4" style="width: 100%" v-model="remetentesAdicionais"></textarea>
            </div>
            <div class="m-t-xs font-12"><a @click="adicionarRemetentes = true">Adicionar mais destinatários</a></div>
            <div class="tip2">
              Anexos
            </div>
            <div class="tip2" v-for="anexo in anexos">
              {{ anexo.nome }}
            </div>
            <div class="tip2 tip2-content no-border" v-if="!anexos || anexos.length < 1">Nenhum anexo</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.side-panel-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3000;
  display: none;
  background-color: rgba(0, 0, 0, .4);
  transform: translateZ(0);
  overflow: hidden;

  &.open {
    display: block;
  }
}

.side-panel-close {
  position: absolute;
  top: 0;
  left: -64px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity .3s;
}

.side-panel-close-inner:after, .side-panel-close-inner:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 2px;
  background-color: #fff;
  content: "";
}

.side-panel-close:hover .side-panel-close-inner {
  border: 2px solid rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, .2);
}

.side-panel-close-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -18px;
  margin-left: -18px;
  width: 32px;
  height: 32px;
  border: 2px solid rgba(255, 255, 255, .7);
  border-radius: 50%;
  background-color: rgba(0, 0, 0, .1);
  opacity: 1;
  transition: all 300ms ease;
  cursor: pointer;

  &:before {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    -moz-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    -o-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }

  &:after {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
}

.view-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3001;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  visibility: hidden;
  width: calc(100% - 300px);
  background: #ADA996; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  /*display: none;*/
  transform: translateX(100%);
  -webkit-transition: all .22s ease-in;
  -moz-transition: all .22s ease-in;
  -ms-transition: all .22s ease-in;
  -o-transition: all .22s ease-in;
  transition: all .22s ease-in;

  &.open {
    transform: translateX(0%);
    display: flex;
    visibility: visible;
  }

  .panel-opt {
    padding: 30px;
    flex: 0;
  }

  .panel-rest {
    display: flex;
    flex: 1;
    height: 0;
    margin: 0 30px 30px;
    flex-direction: row;
  }

  .inner-html {
    background-color: #ffffff;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    overflow: auto;

    &.loaded {
      display: block;
    }
  }

  .options {
    flex: 0 280px;
    background-color: #FFFFFF;
    margin-left: 15px;
    overflow auto

    .tip1 {
      padding: 15px 0 17px;
      border-bottom: 1px solid #eef2f4;
      overflow: hidden;

      .title {
        font-size: 13px;
        color: #858c96;
        width: 90px;
        float: left;
        word-wrap: break-word;
      }

      .value {
        color: #000;
        margin-left: 100px;
        padding-left: 5px;
      }
    }

    .tip2 {
      margin-top: 15px;
      color: #525c69;
      border-bottom: 1px solid #edeef0;
      line-height: 32px;

      &.no-border {
        border-bottom: none;
      }

      &.tip2-content {
        line-height: 1.0;
      }
    }
  }
}
</style>
