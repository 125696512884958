export default function (lote) {
  let window = `lote.${lote.id}.alterarStatus`
  let title = 'Mudar status do lote' + (lote.numero ? ` #${lote.numero}` : ' ID ' + lote.id)

  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: title,
    width: '700',
    height: '450',
    minWidth: '500px',
    minHeight: '400px',
    backdrop: false,
    clickOutside: false,
    maximize: false,
    windowClass: 'erp-window erp-window-macos',
    contentClass: 'overflow-hidden',
    props: {
      lote: lote
    }
  }, () => import('../components/window/MudarStatusLote.vue'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        cancel: (wid, val) => {
          console.log('Cancelado alteracao de status')
          this.load && this.load()
        },
        update: (wid, val) => {
          console.log('Atualização do status com sucesso')
          this.$uloc.notify({color: 'positive', position: 'bottom', message: 'Status modificado com sucesso'})
          this.load && this.load()
        }
      })
      console.log(wid)
    }) // return wid
}
