import * as StatusPagamento from "@/domain/leiloes/helpers/PagamentoStatus";
import {TIPO_PORCENTAGEM} from "@/domain/taxas/helpers/Status";

export default {
  computed: {
    arrematanteHelper() {
      if (this.lote.arremate.arrematante) {
        return `id #${this.mock.arrematante.id} / ${this.mock.arrematante.apelido}`
      }
    },
    compradorHelper() {
      if (this.lote.arremate.comprador) {
        return `id #${this.mock.comprador.id} / ${this.mock.comprador.apelido}`
      }
    },
    ultimoLance() {
      let lances = this.lote.lances
      if (!Array.isArray(lances) || lances.length === 0) {
        return 0
      }
      // let ultimoLance = lances[lances.length - 1]
      let ultimoLance = lances[0]
      return ultimoLance.valor
    },
    lanceAtual() {
      let ultimoLance = this.ultimoLance
      if (!ultimoLance) {
        return '-'
      }
      return 'R$ ' + this.$options.filters.moeda(ultimoLance)
    },
    diferencaSobreMinimo() {
      let ultimoLance = this.ultimoLance
      let valorMinimo = this.$options.filters.moedaToNumber(this.lote.valorMinimo)
      console.log('Valor minimo: ', valorMinimo)
      if (!ultimoLance || !valorMinimo) {
        return '-'
      }
      let rest = ultimoLance - valorMinimo
      if (!isFinite(rest)) {
        return '-'
      }
      return rest
    },
    isMinimoAtingido() {
      return this.diferencaSobreMinimo >= 0
    },
    diferencaSobreMinimoFormated() {
      let rest = this.diferencaSobreMinimo
      return rest === '-' ? '-' : (
        rest < 0 ? '-R$ ' + this.$options.filters.moeda(rest) + ' do mínimo' :
          (rest === 0 ? 'Mínimo atingido' : '+R$ ' + this.$options.filters.moeda(rest))
      )
    },
    impostos() {
      return this.lote.impostos
    },
    /*taxas () {
      if (!this.lote.impostos) {
        return 0
      }
      return this.lote.impostos.filter(i => i.tipo === 'taxaAdministrativa')
    },*/
    totalImposto() {
      let total = 0
      if (!this.lote.impostos) {
        return 0
      }
      this.impostos.map(v => {
        total += v.valor
      })
      return total
    },
    /*totalTaxas () {
      let total = 0
      let base = this.ultimoLance
      if (!this.taxas) {
        return 0
      }
      this.taxas.map(v => {
        total += this.calcularImposto(v, base)
      })
      return total
    },*/
    total() {
      // return 'R$ ' + this.$options.filters.moeda((this.mock.impostos + this.mock.taxas + this.ultimoLance))
      return this.totalImposto + this.ultimoLance/* + this.totalTaxas*/
    },
    totalPago() {
      let total = 0
      if (this.lote.arremate && this.lote.arremate.pagamentos) {
        this.lote.arremate.pagamentos.map(p => {
          if (p.status === StatusPagamento.STATUS_PAGAMENTO_ARREMATE_CONFIRMADO) {
            total += p.valor
          }
        })
      }
      return total
    },
    saldo() {
      return this.lote.saldoDevedor * -1
    }
  },
  methods: {
    mountImpostoLabel(imposto) {
      let label
      label = imposto.nome
      if (parseInt(imposto.tipo) === TIPO_PORCENTAGEM) {
        return label + ` (${imposto.valor}%):`
      }
      return label + ':'
    }
  }
}
