<template>
  <div>
    <!--<div class=" flex justify-between" style="min-height: 160px">-->
      <div class="col-grow flex justify-between h-full">
        <!-- TODO: BUG: Window table need an max-height prop and ajsut scroll and thead -->
        <e-window-table class="col-grow h-full bg-black text-white"
                        :columns="['Data da inclusão', 'Apelido', 'Valor Limite','Parcelado', 'Status', 'Opções']"
        > <!-- 'Nº lances', -->
          <e-tr v-for="(data, index) in filteredLances" :key="index">
            <e-td>{{ data.data.date|formatDate('dd/MM/yyyy HH:mm:ss') }}</e-td>
            <e-td><span :style="data.arrematante.apelido === 'PLATEIA' ? 'color: #0448E6' : ''">{{ data.arrematante.apelido }}</span></e-td>
            <e-td class="text-nowrap">R$ {{ data.valorLimite | moeda }}</e-td>
            <e-td>
              <span v-if="data.parcelado"><strong class="text-warning">Sim, {{data.parcelas}} parcela(s)</strong></span>
              <span v-else>Não</span>
            </e-td>
            <e-td>
              <span v-if="index === 0"><strong class="text-positive">Melhor oferta</strong></span>
              <span v-else>Superado/Ignorado</span>
            </e-td>
            <e-td><a @click="open(data)"><i class="erp-icon search min"></i> </a></e-td>
          </e-tr>
        </e-window-table>
      </div>
    <!--</div>-->
  </div>
</template>

<script>
import {ETd, ETr, EWindowTable} from 'uloc-vue-plugin-erp'
// import LanceRegistroStatus from './LanceRegistroStatus'
import lanceWindow from '../../../windows/lanceAutomatico'

export default {
  name: 'HistoricoLancesAutomaticos',
  props: {
    lote: {
      required: true
    }
  },
  computed: {
    filteredLances () {
      if (!this.lote.lancesAutomaticos) {
        return []
      }
      return this.lote.lancesAutomaticos.slice().sort(function(a, b) {
        return a.valorLimite < b.valorLimite ? 1 : -1
      })
    }
  },
  components: {
    // LanceRegistroStatus,
    EWindowTable,
    ETr,
    ETd
  },
  data () {
    return {}
  },
  methods: {
    lanceWindow: lanceWindow,
    open (lance) {
      this.lanceWindow(lance)
    }
  }
}
</script>
