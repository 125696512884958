export default function (lanceAutomatico) {
  let window, title
  if (typeof lanceAutomatico !== 'undefined' && lanceAutomatico) {
    window = lanceAutomatico.id ? `lanceAutomatico.${lanceAutomatico.id}` : 'lanceAutomatico.new'
    title = 'Lance Automático ' + `#${lanceAutomatico.id}`
  } else {
    window = 'lanceAutomatico.new'
    title = 'Lance'
  }

  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: title,
    width: '800',
    height: '300',
    minWidth: '600px',
    minHeight: '300px',
    backdrop: false,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden',
    props: {
      id: lanceAutomatico.id
    }
  }, () => import('../components/window/LanceAutomatico.vue'))
    .then((wid) => {
      console.log(wid)
    }) // return wid
}
