<template>
  <div v-if="arrematante">
    <email-formalizacao-view @update="load" ref="formalizacaoView"></email-formalizacao-view>
    <arquivos-arrematante :lote="lote" />
    <div class="m-t-lg">
      <u-btn @click="$refs.formalizacaoView.load(lote.id)" icon="handshake" icon-type="fa" no-caps color="blue-grey-8" label="Formalizar / comunicar venda com o arrematante" />
      <u-btn icon="handshake" icon-type="fa" no-caps color="blue-grey-6" label="Formalizar com carimbo do tempo" class="m-l" />
    </div>
  </div>
  <div v-else>Lote sem arremate homologado</div>
</template>

<script>
import ArquivosArrematante from "components/arrematante/components/include/Arquivos";
import EmailFormalizacaoView from "components/leiloes/components/cobranca/EmailFormalizacaoView";
export default {
  name: "LoteFormalizacao",
  inject: ['loteContainer'],
  provide: function () {
    return {
      arrematanteContainer: this
    }
  },
  computed: {
    lote () {
      return this.loteContainer.lote
    },
    arrematante () {
      return this.loteContainer.lote.arremate ? this.loteContainer.lote.arremate.arrematante : null
    }
  },
  components: {
    ArquivosArrematante,
    EmailFormalizacaoView,
  },
  methods: {
    load () {}
  }
}
</script>
