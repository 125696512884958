<script>
import {UPopover} from 'uloc-vue'
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
import windowBem from '../../../bem/windows/bem'
import windowCancelarVenda from '../../../pda/components/apps/arrematante/cancelarVenda'

export default {
  name: 'BtnAcoesLote',
  props: ['lote'],
  components: {MenuOptionsItem, UPopover},
  computed: {
  },
  methods: {
    windowBem: windowBem,
    windowCancelarVenda: windowCancelarVenda
  }
}
</script>

<template>
  <e-btn align="left" label="Ações">
    <span class="m-l-md"></span> <u-icon name="chevron-down" type="fa" class="absolute-right m-l m-r-sm text-grey-7" style="font-size: 10px !important;"/>
    <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
      <div class="menu_ menu-btn-leilao">
        <ul>
          <menu-options-item @click="windowBem(lote.bem.id)" label="Acessar/Editar Bem" close  />
        </ul>
        <ul v-if="lote.arremate">
          <menu-options-item label-class="text-negative" @click="windowCancelarVenda(lote)" label="Cancelar venda" close  />
        </ul>
        <slot />
      </div>
    </u-popover>
  </e-btn>
</template>
