<template>
  <div class="content">
    <table class="resumo-v-table font-avenir m-t-lg resumo-fin extrato_">
      <tbody>
      <tr>
        <td><span>Emissão da nota</span></td>
        <td class="text-right"><span>XXX</span></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "LoteFiscal"
}
</script>
