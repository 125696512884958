<script>
import {UPopover} from 'uloc-vue'
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
import printRecibo from "@/components/pda/print/recibo"
import printNota from "@/components/pda/print/nota"
import printExtrato from "@/components/pda/print/extrato"
import termoRetirada from "@/components/pda/print/termoRetirada"
import termoAlienacao from "@/components/pda/print/termoAlienacao"
import autoArrematacao from "@/components/pda/print/autoArrematacao"
import {faturaArrematacao} from "@/domain/leiloes/services/financeiro"
import {donwloadFile} from "@/utils/downloadFile"
import autoNegativoArrematacao from "components/pda/print/autoNegativoArrematacao";
import autoNegativoArrematacao2 from "components/pda/print/autoNegativoArrematacao2";

export default {
  name: 'BtnEmissoesLote',
  props: ['lote'],
  components: {MenuOptionsItem, UPopover},
  computed: {
  },
  methods: {
    printReciboWindow (lotes) {
      // let route = this.$router.resolve({name: 'print.lote.recibo', params: {id: }})
      window.open(`/#/print/lotes/${this.lote.id}/recibo`, '_blank')
    },
    printReciboComitenteWindow (lotes) {
      // let route = this.$router.resolve({name: 'print.lote.recibo', params: {id: }})
      window.open(`/#/print/lotes/${this.lote.id}/reciboComitente`, '_blank')
    },
    printRecibo: printRecibo,
    printNotaWindow: printNota,
    printNota (lotes, tipo) {
      const step2 = (salvar = false) => {
        this.$uloc.dialog({
          title: 'Enviar nota para o arrematante',
          message: 'Deseja enviar a nota para o arrematante e salvá-la em seu cadastro?',
          ok: 'Sim',
          cancel: 'Não',
        }).then(() => {
          this.printNotaWindow(lotes, tipo, salvar, true)
        }).catch(() => {
          this.printNotaWindow(lotes, tipo, salvar, false)
        })
      }
      this.$uloc.dialog({
        title: 'Emitir nota',
        message: 'Deseja salvar nota como anexo do bem?',
        ok: 'Sim',
        cancel: 'Não',
      }).then(() => {
        step2(true)
      }).catch(() => {
        step2(false)
      })
    },
    termoRetirada: termoRetirada,
    termoAlienacao: termoAlienacao,
    autoArrematacao: autoArrematacao,
    autoNegativoArrematacao: autoNegativoArrematacao,
    autoNegativoArrematacao2: autoNegativoArrematacao2,
    emitirFatura () {
      faturaArrematacao(this.lote.leilao.id, this.lote.arremate.comprador ? this.lote.arremate.comprador.id : this.lote.arremate.arrematante.id)
          .then((response) => {
            donwloadFile(response)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    printExtrato: printExtrato
  }
}
</script>

<template>
  <e-btn icon="print" icon-type="fa" icon-style="light" align="left" label="Emissão">
    <span class="m-l-md"></span> <u-icon name="chevron-down" type="fa" class="absolute-right m-l m-r-sm text-grey-7" style="font-size: 10px !important;"/>
    <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
      <div class="menu_ menu-btn-leilao">
        <ul v-if="lote.arremate">
          <menu-options-item icon="file-alt" icon-type="fa" icon-style="light" label="Nota" close @click="printNota([lote], 'unificado')"  />
        </ul>
        <ul v-if="lote.arremate">
          <menu-options-item icon="file-pdf" icon-type="fa" icon-style="light" label="Fatura de Arrematação" close @click="emitirFatura"  />
        </ul>
        <ul v-if="lote.arremate">
          <menu-options-item icon="receipt" icon-type="fa" icon-style="light" label-class="text-green" label="Recibo" close @click="printReciboWindow([lote])" />
        </ul>
        <ul v-if="lote.arremate">
          <menu-options-item icon="receipt" icon-type="fa" icon-style="light" label="Recibo do Comitente" close @click="printReciboComitenteWindow([lote])" />
        </ul>
        <ul v-if="lote.arremate">
          <menu-options-item icon="receipt" icon-type="fa" icon-style="light" label="Extrato Unificado" close @click="printExtrato(lote)" />
        </ul>
        <ul v-if="lote.arremate">
          <menu-options-item icon="file-alt" icon-type="fa" icon-style="light" label="Auto Positivo" close @click="autoArrematacao([lote])" />
        </ul>
        <ul v-if="!lote.arremate">
          <menu-options-item icon="file-alt" icon-type="fa" icon-style="light" :label="lote.leilao.instancia > 1 ? 'Auto Negativo 1º Leilão' : 'Auto Negativo'" close @click="autoNegativoArrematacao([lote])" />
        </ul>
        <ul v-if="!lote.arremate && lote.leilao.instancia > 1 ">
          <menu-options-item icon="file-alt" icon-type="fa" icon-style="light" label="Auto Negativo 2º Leilão" close @click="autoNegativoArrematacao2([lote])" />
        </ul>
        <ul v-if="lote.arremate">
          <menu-options-item icon="file-alt" icon-type="fa" icon-style="light" label="Termo de Alienação" close @click="termoAlienacao([lote])" />
        </ul>
<!--        <ul>
          <menu-options-item icon="file-alt" icon-type="fa" icon-style="light" label="Certidão de publicação" close />
        </ul>-->
        <slot />
      </div>
    </u-popover>
  </e-btn>
</template>
