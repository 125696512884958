<template>
  <div v-if="!simple" class="detalhes-venda-lote" :class="bindClass">
    <div class="row erp-row m-b-n">
      <div class="col" style="min-width: 40%">
        <div class="row erp-row">
          <div class="col">
            <erp-s-field
                label="Arrematante:"
                view="lr"
                label-width="120px"
            >
              <strong>{{lote.arremate.arrematante.pessoa.name}}</strong>
            </erp-s-field>
          </div>
        </div>
        <div class="row erp-row">
          <div class="col">
            <erp-s-field
                label="Data Arremate:"
                view="lr"
                label-width="120px"
            >
              <strong>{{lote.arremate.data.date|formatDate('dd/MM/yyyy HH:mm:ss')}}</strong>
            </erp-s-field>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row erp-row">
          <div class="col">
            <erp-s-field
                label="Apelido:"
                view="lr"
                label-width="80px"
            >
              <strong>{{lote.arremate.arrematante.apelido}}</strong>
            </erp-s-field>
          </div>
        </div>
        <div class="row erp-row">
          <div class="col">
            <erp-s-field
                label="Valor:"
                view="lr"
                label-width="80px"
            >
              <strong>R$ {{(lote.valorArremate + lote.valorImpostos)|moeda}}</strong>
            </erp-s-field>
          </div>
        </div>
      </div>
      <div class="col" style="width: 38%">
        <div class="row erp-row">
          <div class="col">
            <erp-s-field
                label="Status do lance:"
                view="lr"
                label-width="80px"
            >
              <lance-status :lance="lote.arremate.lance" />
            </erp-s-field>
          </div>
        </div>
        <div class="row erp-row">
          <div class="col">
            <erp-s-field
                label="Status da venda:"
                class="font-bold"
                view="lr"
                label-width="80px"
            >
              <status-lote :lote="lote" />
            </erp-s-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <e-row>
      <e-col>
        <erp-s-field
            label="Arrematante:"
            view="lr"
            label-width="80px"
        >
          <strong>{{lote.arremate.arrematante.pessoa.name}}</strong>
        </erp-s-field>
      </e-col>
    </e-row>
    <e-row>
      <e-col>
        <erp-s-field
            label="Valor:"
            view="lr"
            label-width="80px"
        >
          <strong>R$ {{lote.arremate.lance.valor|moeda}}</strong>
        </erp-s-field>
      </e-col>
    </e-row>
    <e-row>
      <e-col>
        <erp-s-field
            label="Lance:"
            view="lr"
            label-width="80px"
        >
          <lance-status :lance="lote.arremate.lance" />
        </erp-s-field>
      </e-col>
    </e-row>
    <e-row>
      <e-col>
        <erp-s-field
            label="Pagamento:"
            view="lr"
            label-width="80px"
        >
          <status-lote :lote="lote" />
        </erp-s-field>
      </e-col>
    </e-row>
  </div>
</template>

<script>
import {ErpSField} from 'uloc-vue-plugin-erp'
import StatusLote from '../StatusLote'
import LanceStatus from './LanceStatus'
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import * as Status from '../../../../../domain/leiloes/helpers/LoteStatus'
export default {
  name: 'DetalheVenda',
  props: {
    lote: {
      required: true
    },
    simple: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    bindClass () {
      let css = []
      if (this.lote.status === Status.STATUS_CANCELADO){
        css.push('venda-cancelada')
      }
      return css
    }
  },
  components: {
    LanceStatus,
    ErpSField,
    StatusLote,
    ERow,
    ECol
  }
}
</script>

<style lang="stylus">
  .detalhes-venda-lote{
    background-color #F0FFE2
    border #707070 1px solid
    box-shadow: 1px 2px 5px 0px rgba(0,0,0,0.16)
    padding 10px
    &.venda-cancelada{
      background-color #fdd5d5
    }
  }
</style>
