<template>
  <div>
    <div>
      <erp-s-field label="Previsão de encerramento">
        <div v-if="lote.dataFechamento" class="font-bold">{{ lote.dataFechamento|formatDate('dd/MM/yyyy HH:mm:ss') }}</div>
        <div v-else>-</div>
      </erp-s-field>
    </div>
    <div class="m-t">
      <erp-s-field label="Último Lance">
        <div v-if="lote.lances && lote.lances.length" class="font-bold">{{ lote.lances[0].data|formatDate('dd/MM/yyyy HH:mm:ss') }}</div>
        <div v-else>-</div>
      </erp-s-field>
    </div>
    <div class="m-t">
      <erp-s-field label="Limite definido após lance durante o encerramento">
        <div v-if="lote.dataLimiteLances" class="font-bold">{{ lote.dataLimiteLances|formatDate('dd/MM/yyyy HH:mm:ss') }}</div>
        <div v-else>-</div>
      </erp-s-field>
    </div>
    <div class="m-t">
      <erp-s-field label="Homologação do arremate">
        <div v-if="lote.arremate" class="font-bold">{{ lote.arremate.data|formatDate('dd/MM/yyyy HH:mm:ss') }}</div>
        <div v-else>-</div>
      </erp-s-field>
    </div>
    <div class="m-t">
      <erp-s-field label="Última modifificação no lote">
        <div v-if="lote.dateModified" class="font-bold">{{ lote.dateModified|formatDate('dd/MM/yyyy HH:mm:ss') }}</div>
        <div v-else>-</div>
      </erp-s-field>
    </div>
  </div>
</template>

<script>
import {ErpSField} from "uloc-vue-plugin-erp"
export default {
  name: "Estatisticas",
  inject: ['loteContainer'],
  provide: function () {
    return {
      arrematanteContainer: this
    }
  },
  computed: {
    lote () {
      return this.loteContainer.lote
    },
    arrematante () {
      return this.loteContainer.lote.arremate ? this.loteContainer.lote.arremate.arrematante : null
    }
  },
  components: {
    ErpSField
  },
  methods: {
    load () {}
  }
}
</script>
