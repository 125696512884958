<script>
import {UWindowHeaderExtButtons, UTooltip, event} from 'uloc-vue'
import windowBem from "components/bem/windows/bem";

export default {
  inject: ['loteContainer'],
  name: 'buttons-lote-window',
  mixins: [UWindowHeaderExtButtons],
  data() {
    return {}
  },
  mounted () {
    if (!this.$router && this.loteContainer && this.loteContainer.router) {
      this.router = this.loteContainer.router
    }
  },
  computed: {
    lote() {
      return this.loteContainer.lote
    },
    bem() {
      return this.lote.bem
    },
    emissor () {
      return this.loteContainer.$refs.emissor
    }
  },
  methods: {
    windowBem: windowBem,
    nota (){
      this.emissor.printNota([this.lote], 'unificado')
    },
    fatura (){
      this.emissor.emitirFatura()
    },
    recibo (){
      this.emissor.printReciboWindow([this.lote])
    },
    extrato (){
      this.emissor.printExtrato(this.lote)
    }
  },
  components: {UTooltip}
}
</script>

<template>
  <div class="ext-btns inline m-r">
    <a @click="windowBem(bem.id)" class="m-l">
      <u-icon name="product-hunt" type="fa" icon-style="brand"/>
      <u-tooltip :offset="[4,4]">Editar bem</u-tooltip>
    </a>
    <a @click="nota" class="m-l-sm">
      <u-icon name="book" type="fa" icon-style="light"/>
      <u-tooltip :offset="[4,4]">Nota</u-tooltip>
    </a>
    <a @click="fatura" class="m-l-sm">
      <u-icon name="file-invoice" type="fa" icon-style="light"/>
      <u-tooltip :offset="[4,4]">Fatura</u-tooltip>
    </a>
    <a @click="recibo" class="m-l-sm">
      <u-icon name="receipt" type="fa" icon-style="light"/>
      <u-tooltip :offset="[4,4]">Recibo</u-tooltip>
    </a>
    <a @click="extrato" class="m-l-sm">
      <u-icon name="file-signature" type="fa" icon-style="light"/>
      <u-tooltip :offset="[4,4]">Extrato</u-tooltip>
    </a>
  </div>
</template>
