export default function (lance) {
  let window, title
  if (typeof lance !== 'undefined' && lance) {
    window = lance.id ? `lance.${lance.id}` : 'lance.new'
    title = 'Lance ' + `#${lance.id}`
  } else {
    window = 'lance.new'
    title = 'Lance'
  }

  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: title,
    width: '800',
    height: '300',
    minWidth: '600px',
    minHeight: '300px',
    backdrop: false,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden',
    props: {
      id: lance.id
    }
  }, () => import('../components/window/Lance.vue'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        updated: (wid, storage) => {
          // console.log('Created event')
          this.load && this.load()
        }
      })
    }) // return wid
}
