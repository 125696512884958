import { render, staticRenderFns } from "./DetalheVenda2.vue?vue&type=template&id=49c55009&"
import script from "./DetalheVenda2.vue?vue&type=script&lang=js&"
export * from "./DetalheVenda2.vue?vue&type=script&lang=js&"
import style0 from "./DetalheVenda2.vue?vue&type=style&index=0&id=49c55009&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports