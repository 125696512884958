<template>
  <div class="detalhe-venda2">
    <e-row>
      <e-col class="vlabel">
        Valor de Arremate
      </e-col>
      <e-col class="vvalue">
        R$ {{lote.arremate.lance.valor|moeda}}
      </e-col>
    </e-row>
    <e-row class="thin">
      <e-col class="vlabel">
        % Valor Mercado/Fipe sobre a arremate
      </e-col>
      <e-col class="vvalue">
        {{mercadoSobreArremate}}%
      </e-col>
    </e-row>
    <e-row class="thin">
      <e-col class="vlabel">
        Diferença do arremate sobre o mínimo
      </e-col>
      <e-col class="vvalue">
        {{diferencaSobreMinimoFormated}} ({{diferencaSobreMinimoPorcentagem}}%)
      </e-col>
    </e-row>
    <e-row>
      <e-col class="vlabel">
        Impostos + comissão + taxas
      </e-col>
      <e-col class="vvalue">
        R$ {{impostos|moeda}}
      </e-col>
    </e-row>
    <e-row>
      <e-col class="vlabel">
        <span style="font-size: 13px">Total a pagar</span>
      </e-col>
      <e-col class="vvalue">
        <span style="font-size: 13px; color: #1467D2">R$ {{totalPagar|moeda}}</span>
      </e-col>
    </e-row>

    <e-row>
      <e-col class="vlabel">
        <span style="font-size: 13px">Total pago</span>
      </e-col>
      <e-col class="vvalue">
        <span style="font-size: 13px;">R$ {{totalPago|moeda}}</span>
      </e-col>
    </e-row>

    <e-row>
      <e-col class="vlabel">
        <span style="font-size: 13px">Saldo</span>
      </e-col>
      <e-col class="vvalue">
        <span style="font-size: 13px;" :class="bindSaldoClass">R$ {{saldo|moeda}}</span>
      </e-col>
    </e-row>
  </div>
</template>

<script>
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import {porcentagemDiferenca} from '../../../../../domain/leiloes/converters/calculos'
import {convertRealToMoney} from '../../../../../utils/money'

export default {
  name: 'DetalheVenda2',
  props: ['lote', 'diferencaSobreMinimo', 'impostos', 'totalPagar', 'totalPago', 'saldo'],
  components: {ECol, ERow},
  computed: {
    mercadoSobreArremate () {
      return porcentagemDiferenca(this.lote.valorArremate, convertRealToMoney(this.lote.valorMercado))
    },
    diferencaSobreMinimoFormated () {
      let rest = this.diferencaSobreMinimo
      return rest === '-' ? '-' : (
        rest < 0 ? '-R$ ' + this.$options.filters.moeda(rest) :
          (rest === 0 ? 'Mínimo atingido' : '+R$ ' + this.$options.filters.moeda(rest))
      )
    },
    diferencaSobreMinimoPorcentagem () {
      let calc = ((this.lote.arremate.lance.valor * 100) / convertRealToMoney(this.lote.valorMinimo)) - 100
      if (isNaN(calc) || !isFinite(calc)) {
        return '?'
      }
      return calc.toFixed(2)
    },
    bindSaldoClass () {
      let css = []
      if (this.saldo < 0) {
        css.push('text-negative')
      }
      if (this.saldo > 0) {
        css.push('text-positive')
      }
      return css
    }
  }
}
</script>

<style lang="stylus">
  .detalhe-venda2 {
    color #333333
    font-size 11px

    .erp-row {
      padding-top 8px !important
      padding-bottom 5px !important
      border-bottom #999999 1px dashed

      .col {
        padding-bottom 0
        margin-bottom 0
      }

      &.thin {
        color #A9A9A9
      }
    }

    .vlabel {
      text-align left
      font-weight bold
    }

    .vvalue {
      text-align right
      font-weight bold
    }
  }
</style>
