<template>
  <div>
    <!--<div class=" flex justify-between" style="min-height: 160px">-->
      <div class="col-grow flex justify-between h-full">
        <!-- TODO: BUG: Window table need an max-height prop and ajsut scroll and thead -->
        <e-window-table class="col-grow h-full"
                        :columns="['Data', 'Apelido', 'Valor', 'Status', 'Opções']"
        > <!-- 'Nº lances', -->
          <e-tr v-for="(data, index) in filteredLances" :key="index">
            <e-td>{{ data.data.date|formatDate('dd/MM/yyyy HH:mm:ss') }}</e-td>
            <e-td><span :style="data.arrematante.apelido === 'PLATEIA' ? 'color: #0448E6' : ''">{{ data.arrematante.apelido }}</span></e-td>
            <e-td class="text-nowrap">R$ {{ data.valor | moeda }}<span class="lanceParcelado m-l-xs" v-if="data.parcelado">parcelado em {{data.parcelas}}x</span></e-td>
            <!--<e-td class="text-nowrap">...</e-td>-->
            <e-td>
              <lance-registro-status :lance="data" />
            </e-td>
            <e-td><a @click="open(data)"><i class="erp-icon search min"></i> </a></e-td>
          </e-tr>
        </e-window-table>
      </div>
    <!--</div>-->
  </div>
</template>

<script>
import {ETd, ETr, EWindowTable} from 'uloc-vue-plugin-erp'
import LanceRegistroStatus from './LanceRegistroStatus'
import lanceWindow from '../../../windows/lance'

export default {
  name: 'HistoricoLances',
  props: {
    lote: {
      required: true
    }
  },
  computed: {
    filteredLances () {
      if (!this.lote.lances) {
        return []
      }
      return this.lote.lances.slice().sort(function(a, b) {
        return a.valor < b.valor ? 1 : -1
      })
    }
  },
  components: {
    LanceRegistroStatus,
    EWindowTable,
    ETr,
    ETd
  },
  data () {
    return {}
  },
  methods: {
    /* status (s) {
      if (typeof s !== 'undefined') {
        return String(s)
      }
      return false
    }, */
    lanceWindow: lanceWindow,
    open (lance) {
      this.lanceWindow(lance)
    },
    load () {
      this.$emit('reload')
    }
  }
}
</script>
