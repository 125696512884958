<template>
  <div v-if="lance.status === Status.STATUS_VENCEDOR">
    <e-btn-circle-status status="positive" class="sm m-r-xs fa-fw" /><span class="text-positive">Aprovado</span>
  </div>
  <div v-else-if="lance.status === Status.STATUS_CANCELADO">
    <e-btn-circle-status status="negative" class="sm m-r-xs fa-fw" /><span class="text-negative">Cancelado</span>
  </div>
  <div v-else-if="lance.status === Status.STATUS_REPROVADO">
    <e-btn-circle-status status="negative" class="sm m-r-xs fa-fw" /><span class="text-negative">Reprovado</span>
  </div>
  <div v-else>
    -
  </div>
</template>

<script>
import {EBtnCircleStatus} from 'uloc-vue-plugin-erp'
import * as Status from '../../../../../domain/leiloes/helpers/LanceStatus'

export default {
  name: 'LanceStatus',
  props: ['lance'],
  computed: {
    Status () {
      return Status
    }
  },
  components: {EBtnCircleStatus}
}
</script>
