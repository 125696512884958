export const porcentagemDiferenca = function (v1, v2) {
  v1 = Number(v1)
  v2 = Number(v2)
  if (isNaN(v1) || isNaN(v2) || (v1 === 0 && v2 === 0)) {
    return 0
  }
  let calc = ((v1 * 100) / v2).toFixed(2)
  if (isNaN(calc) || !isFinite(calc)) {
    return 0
  }
  return calc
}
