<template>
  <div>
    <!--<div class=" flex justify-between" style="min-height: 160px">-->
    <div class="col-grow flex justify-between h-full">
      <!-- TODO: BUG: Window table need an max-height prop and ajsut scroll and thead -->
      <e-window-table height="160px" class="col-grow h-full"
                      :columns="['Transação', 'Data', 'Forma de pagamento', 'Valor', 'Observações', 'Opções']"
      > <!-- 'Nº lances', -->
        <e-tr v-for="(p, index) in filteredPagamentos" :key="index">
          <e-td :class="{'text-negative': Number(p.status) === Status.STATUS_ESTORNADO || Number(p.status) === Status.STATUS_CANCELADO}">{{String(p.id).padStart(10, '0')}}</e-td>
          <e-td>{{p.data.date|formatDate}}</e-td>
          <e-td>{{p.metodo && p.metodo.nome}}</e-td>
          <e-td>
            R$ {{p.valor|moeda}}
            <span v-if="Number(p.status) === Status.STATUS_ESTORNADO" class="text-negative font-10">(Estornado)</span>
            <span v-if="Number(p.status) === Status.STATUS_CANCELADO" class="text-negative font-10">(Cancelado)</span>

          </e-td>
          <e-td>{{p.observacoes}}</e-td>
          <e-td>
            <a @click="open(p.id)"><i class="erp-icon search min"></i> </a>
            <a class="m-l-xs" @click="enviarRecibo(p.id)">
              <i class="erp-icon mensagens min"></i>
              <u-tooltip class="text-center" content-class="bg-indigo text-center" :offset="[8, 8]">Enviar recibo para o arrematante</u-tooltip>
            </a>
          </e-td>
        </e-tr>
        <e-tr v-if="!filteredPagamentos.length">
          <e-td colspan="100%" style="text-align: center !important;">
            Nenhum pagamento registrado
          </e-td>
        </e-tr>
      </e-window-table>
    </div>
    <div v-if="lote.arremate" class="text-right m-t-sm">
      <e-btn @click="registrarPagamento">
        <u-icon name="cash-register" type="fa" class="m-r-xs text-grey-7" />
        Registrar pagamento
      </e-btn>
    </div>
    <!--</div>-->
  </div>
</template>

<script>
import {ETd, ETr, EWindowTable} from 'uloc-vue-plugin-erp'
import {UTooltip} from 'uloc-vue'
import windowRegistroPagamento from '../../../../pda/components/apps/arrematante/registrarPagamento'
import windowLogPagamento from '../../../windows/pagamentoLote'
import * as Status from '../../../../../domain/leiloes/helpers/PagamentoStatus'
import {enviarReciboPagamentoLote} from '@/domain/leiloes/services/financeiro'

export default {
  name: 'RegistroPagamentoLote',
  props: {
    lote: {
      required: true
    }
  },
  computed: {
    filteredPagamentos () {
      if (!this.lote.arremate || !this.lote.arremate.pagamentos) {
        return []
      }
      return this.lote.arremate.pagamentos
    },
    Status () {
      return Status
    }
  },
  components: {
    EWindowTable,
    ETr,
    ETd,
    UTooltip
  },
  data () {
    return {}
  },
  methods: {
    /* status (s) {
      if (typeof s !== 'undefined') {
        return String(s)
      }
      return false
    }, */
    windowRegistroPagamento: windowRegistroPagamento,
    windowLogPagamento: windowLogPagamento,
    registrarPagamento () {
      this.windowRegistroPagamento(this.lote.arremate.arrematante, [this.lote])
    },
    open (id) {
      this.windowLogPagamento(id)
    },
    enviarRecibo (id) {
      this.$uloc.loading.show()
      this.$uloc.notify({
        color: 'info',
        message: `Enviando recibo.`
      })
      enviarReciboPagamentoLote(id)
          .then(() => {
            this.$uloc.loading.hide()
            this.$uloc.notify({
              color: 'positive',
              message: `Recibo enviado`
            })
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    }
  }
}
</script>
