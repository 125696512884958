<template>
  <div>
    <p class="font-11 font-bold">Lances apagados ({{lances ? lances.length : 0}})</p>
    <!--<div class=" flex justify-between" style="min-height: 160px">-->
      <div class="col-grow flex justify-between h-full">
        <!-- TODO: BUG: Window table need an max-height prop and ajsut scroll and thead -->
        <e-window-table class="col-grow h-full"
                        :columns="['Data', 'Apelido', 'Valor', 'Status', 'Modificado por', 'Data Modificação', 'Opções']"
        > <!-- 'Nº lances', -->
          <e-tr v-for="(data, index) in filteredLances" :key="index">
            <e-td>{{ data.data.date|formatDate('dd/MM/yyyy HH:mm:ss') }}</e-td>
            <e-td><span :style="data.arrematante.apelido === 'PLATEIA' ? 'color: #0448E6' : ''">{{ data.arrematante.apelido }}</span></e-td>
            <e-td class="text-nowrap">R$ {{ data.valor | moeda }}<span class="lanceParcelado m-l-xs" v-if="data.parcelado">parcelado</span></e-td>
            <!--<e-td class="text-nowrap">...</e-td>-->
            <e-td>
              <e-btn-circle-status status="negative" class="sm m-r-xs fa-fw" /><span class="text-negative">Deletado</span>
            </e-td>
            <e-td>{{data.modifiedByUser}}</e-td>
            <e-td>{{data.dateModified|formatDate('dd/MM/yyyy HH:mm:ss')}}</e-td>
            <e-td><!--<a @click="open(data)"><i class="erp-icon search min"></i> </a>--></e-td>
          </e-tr>
        </e-window-table>
      </div>
    <!--</div>-->
  </div>
</template>

<script>
import {ETd, ETr, EWindowTable, EBtnCircleStatus} from 'uloc-vue-plugin-erp'
//import LanceRegistroStatus from './LanceRegistroStatus'
import lanceWindow from '../../../windows/lance'
import {findLancesApagados} from "@/domain/leiloes/services"

export default {
  name: 'HistoricoLancesApagados',
  props: {
    lote: {
      required: true
    }
  },
  data () {
    return {
      lances: []
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    filteredLances () {
      if (!this.lances) {
        return []
      }
      return this.lances.slice().sort(function(a, b) {
        return a.valor < b.valor ? 1 : -1
      })
    }
  },
  components: {
    //LanceRegistroStatus,
    EBtnCircleStatus,
    EWindowTable,
    ETr,
    ETd
  },
  methods: {
    /* status (s) {
      if (typeof s !== 'undefined') {
        return String(s)
      }
      return false
    }, */
    load () {
      findLancesApagados(this.lote.id)
         .then((response) => {
           this.lances = response.data
         })
        .catch(error => {
          this.alertApiError(error)
        })
    },
    lanceWindow: lanceWindow,
    open (lance) {
      this.lanceWindow(lance)
    }
  }
}
</script>
